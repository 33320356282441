import React from 'react';
import { Layout } from 'src/components';
import { SITE_METADATA } from 'src/constants';
import TopBanner from 'src/components/Campaign/merchant/atobaraisodanshitsu/TopBanner';
import Campaign from 'src/components/Campaign/merchant/202311_futureshop/Campaign';
import Merits from 'src/components/Campaign/merchant/atobaraisodanshitsu/Merits';
import WhatIsPaidy from 'src/components/Campaign/merchant/atobaraisodanshitsu/WhatIsPaidy';
import NPay from 'src/components/Campaign/merchant/atobaraisodanshitsu/NPay';
import Video from 'src/components/Merchant/Video';
import HowToPay from 'src/components/Campaign/merchant/atobaraisodanshitsu/HowToPay';
import Cycle from 'src/components/Campaign/merchant/atobaraisodanshitsu/Cycle';
import Application from 'src/components/Campaign/merchant/atobaraisodanshitsu/Application';

const SEOProps = {
  title: SITE_METADATA.campaign_202311_futureshop.title,
  description: SITE_METADATA.campaign_202311_futureshop.description,
};

export default function Campaign202311Futureshop() {
  return (
    <Layout hasNoHeader hasNoFooter SEOProps={SEOProps}>
      <TopBanner />
      <Campaign />
      <Merits />
      <WhatIsPaidy />
      <NPay />
      <Video
        poster={require('src/images/campaign/merchant/atobaraisodanshitsu/poster.png')}
      />
      <HowToPay />
      <Cycle />
      <Application />
    </Layout>
  );
}
